/**
 * Privacy-Plan WebApp
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type StatementView = 'READ_ONLY' | 'EDIT' | 'RECALL' | 'REVIEW' | 'SUBMIT' | 'CONTROLLER';

export const StatementView = {
    ReadOnly: 'READ_ONLY' as StatementView,
    Edit: 'EDIT' as StatementView,
    Recall: 'RECALL' as StatementView,
    Review: 'REVIEW' as StatementView,
    Submit: 'SUBMIT' as StatementView,
    Controller: 'CONTROLLER' as StatementView
};

