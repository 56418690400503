import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';

// Menu
export interface Menu {
  headTitle?: string;
  headTitle2?: string;
  path?: any;
  title?: string;
  icon?: string;
  type?: string;
  badgeValue?: string;
  badgeClass?: string;
  active?: boolean;
  selected?: boolean;
  bookmark?: boolean;
  children?: Menu[];
  Menusub?: boolean;
  target?: boolean;
  items?: any;

  /**
   * Rights check. If not set, shown for all Authorities.
   * If set, only shown for given authorities.
   */
  showForAuthority?: Authority[];
}

@Injectable({
  providedIn: 'root',
})
export class NavService implements OnDestroy {
  private unsubscriber: Subject<any> = new Subject();
  public screenWidth: BehaviorSubject<number> = new BehaviorSubject(window.innerWidth);

  // Collapse Sidebar
  public collapseSidebar: boolean = window.innerWidth < 991 ? true : false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
  ) {
    this.setScreenWidth(window.innerWidth);
    fromEvent(window, 'resize')
      .pipe(debounceTime(1000), takeUntil(this.unsubscriber))
      .subscribe((evt: any) => {
        this.setScreenWidth(evt.target.innerWidth);
        if (evt.target.innerWidth < 991) {
          this.collapseSidebar = true;
        }
      });
    if (window.innerWidth < 991) {
      // Detect Route change sidebar close
      this.router.events.subscribe(event => {
        this.collapseSidebar = true;
      });
    }
  }

  ngOnDestroy() {
    this.unsubscriber.next;
    this.unsubscriber.complete();
  }

  private setScreenWidth(width: number): void {
    this.screenWidth.next(width);
  }

  MENUITEMS: Menu[] = [
    {
      headTitle: 'menu.assessments',
    },
    {
      title: 'menu.dashboard',
      selected: false,
      icon: 'house',
      type: 'link',
      path: '/home',
    },
    {
      title: 'menu.myAssessment',
      selected: false,
      icon: 'file-earmark-text',
      type: 'link',
      path: '/assessment',
      showForAuthority: [Authority.User],
    },
    {
      headTitle: 'menu.administration',
      showForAuthority: [Authority.Admin, Authority.User, Authority.Company],
    },
    {
      title: 'menu.customers',
      selected: false,
      icon: 'people',
      type: 'link',
      path: '/customers',
      showForAuthority: [Authority.User],
    },
    {
      title: 'menu.suppliers',
      selected: false,
      icon: 'person-lines-fill',
      type: 'link',
      path: '/suppliers',
      showForAuthority: [Authority.User],
    },
    {
      title: 'menu.myCompany',
      selected: false,
      icon: 'building',
      type: 'link',
      path: '/my-company',
      showForAuthority: [Authority.Company],
    },
    {
      title: 'menu.questionnaireAndCategories',
      selected: false,
      icon: 'diagram-3',
      type: 'link',
      path: '/admin',
      showForAuthority: [Authority.Admin],
    },
  ];
  items = new BehaviorSubject<Menu[]>(this.MENUITEMS);
}

export enum Authority {
  Admin = 'menu.admin',
  User = 'User',
  Auditor = 'Auditor',
  Company = 'Company',
}
