import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { BootstrapButtonStyles, BootstrapColors } from 'src/app/shared/models/colors.model';

@Component({
  selector: 'app-save-button',
  templateUrl: './save-button.component.html',
  styleUrls: ['./save-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SaveButtonComponent {
  @Input() isDisabled = false;
  @Input() isSaving = false;
  @Input() label = 'Save';
  @Input() labelArgs?: { [fieldName: string]: string };
  @Input() icon: string = 'floppy';
  @Input() fullWidth = false;
  @Input() size?: 'sm' | 'lg';
  @Input() color: BootstrapColors | BootstrapButtonStyles = 'primary';
  @Input() type: 'button' | 'submit' = 'submit';
  @Input() tooltip?: string;
  @Input() iconOnly = false;
  @Input() hasBorder = false;

  /**
   * Provide either link or clickSave
   */
  @Input() link?: string;

  /**
   * Only use this method for clicking the button!!
   * If (click) is triggered, you can even click disabled buttons
   *
   * @documentation
   */
  @Output() clickSave = new EventEmitter<Event>();

  constructor(private router: Router) {}

  clickSaveButton(event?: Event): void {
    if (this.link) {
      this.router.navigateByUrl(this.link);
    } else {
      this.clickSave.emit(event);
    }
  }
}
