<div class="horizontalMenucontainer">
  <div class="page">
    <app-header></app-header>
    <div class="page-main">
      <!-- // header -->
      <!-- //sidebar -->
      <app-sidebar appHoverEffectSidebar></app-sidebar>

      <div class="main-content app-content mt-0">
        <div class="side-app">
          <div class="main-container container-fluid" (click)="toggleSwitcherBody()">
            <!-- // content  -->
            <router-outlet></router-outlet>
          </div>
        </div>
      </div>
    </div>
    <app-footer></app-footer>
  </div>
  <app-tab-to-top></app-tab-to-top>
  <app-toast></app-toast>
</div>
