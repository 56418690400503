/**
 * Privacy-Plan WebApp
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { StatementView } from './statementView';
import { StatementDataDTO } from './statementDataDTO';
import { RoleInAssessment } from './roleInAssessment';


export interface StatementsDTO { 
    statements?: Array<StatementDataDTO>;
    view?: StatementView;
    roleInAssessment?: RoleInAssessment;
}
export namespace StatementsDTO {
}


