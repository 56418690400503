<amplify-authenticator [formFields]="formFields">
  <ng-template amplifySlot="header">
    <div class="header-top-custom">
      <img class="amplify-image" alt="Processor Compliance logo" src="assets/img/pc-original.svg" />
    </div>
  </ng-template>
  <ng-template amplifySlot="sign-up-header">
    <div class="text-center">
      <h1 class="amplify-heading">{{ 'login.registration' | translate }}</h1>
    </div>
  </ng-template>
  <ng-template amplifySlot="sign-up-footer">
    <div class="text-center">
      <span>{{ 'login.alreadyHaveAnAccount' | translate }}</span>
      <button
        (click)="authenticator.toSignIn()"
        class="font-weight-normal mb-4 ps-1 amplify-button amplify-button--link amplify-button--small"
        data-fullwidth="false"
        data-size="small"
        data-variation="link"
        type="button"
      >
        {{ 'login.signIn' | translate }}
      </button>
    </div>
  </ng-template>
  <ng-template amplifySlot="sign-in-header">
    <div class="text-center">
      <h1 class="amplify-heading">{{ 'login.login' | translate }}</h1>
    </div>
  </ng-template>
  <ng-template amplifySlot="sign-in-footer">
    <div class="text-center">
      <span>{{ 'login.notRegisteredYet' | translate }}</span>
      <button
        (click)="authenticator.toSignUp()"
        class="font-weight-normal mb-4 ps-1 amplify-button amplify-button--link amplify-button--small"
        data-fullwidth="false"
        data-size="small"
        data-variation="link"
        type="button"
      >
        {{ 'login.signUp' | translate }}
      </button>
      <button
        (click)="authenticator.toResetPassword()"
        class="font-weight-normal mb-4 ps-1 amplify-button amplify-button--link amplify-button--small amplify-button--fullwidth"
        data-fullwidth="false"
        data-size="small"
        data-variation="link"
        type="button"
      >
        {{ 'login.resetPassword' | translate }}
      </button>
    </div>
  </ng-template>
  <ng-template amplifySlot="authenticated">
    <app-loader *ngIf="applicationLoading()"></app-loader>
    <router-outlet *ngIf="!applicationLoading()"></router-outlet>
  </ng-template>
</amplify-authenticator>
