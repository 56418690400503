/**
 * Privacy-Plan WebApp
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type Answers = 'YES' | 'NO' | 'NA';

export const Answers = {
    Yes: 'YES' as Answers,
    No: 'NO' as Answers,
    Na: 'NA' as Answers
};

