import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BootstrapColors } from 'src/app/shared/models/colors.model';

@Component({
  selector: 'app-badge',
  templateUrl: './badge.component.html',
  styleUrls: ['./badge.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BadgeComponent {
  @Input() labelKey!: string;
  @Input() color: BootstrapColors = 'warning';
  @Input() isRound = true;
}
