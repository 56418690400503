<button
  [type]="type"
  [class]="(hasBorder ? 'border ' : '') + 'btn btn-' + color + (size ? ' btn-' + size : '')"
  [class.btn-icon-only]="iconOnly"
  [disabled]="isDisabled || isSaving"
  (click)="clickSaveButton($event)"
  [class.btn-block]="fullWidth"
  [ngbTooltip]="tooltip"
>
  <i *ngIf="!isSaving" class="bi bi-{{ icon }}" [class.me-1]="!iconOnly"></i>
  <span *ngIf="isSaving" class="spinner-grow spinner-grow-sm me-1" role="status"></span>
  <span *ngIf="!iconOnly">{{ label }}</span>
</button>
