/**
 * Privacy-Plan WebApp
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Language } from './language';


export interface QuestionTranslationDTO { 
    id?: string;
    headline?: string;
    text?: string;
    description?: string;
    descriptionForAuditor?: string;
    language?: Language;
}
export namespace QuestionTranslationDTO {
}


