import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BootstrapColors } from '../../models/colors.model';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-notification',
  standalone: true,
  imports: [CommonModule, TranslateModule],
  templateUrl: './notification.component.html',
  styleUrl: './notification.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationComponent {
  @Input({ required: true }) text!: string;
  @Input() type: BootstrapColors = 'info';
  @Input() icon?: string;
  @Input() cyTag?: string;

  getErrorMessage(): string {
    switch (this.text) {
      case 'required':
        return 'error.fieldRequired';
      default:
        return this.text;
    }
  }
}
