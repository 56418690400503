/**
 * Privacy-Plan WebApp
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface CountryBE { 
    createdAt?: string;
    updatedAt?: string;
    createdBy?: string;
    updatedBy?: string;
    code: string;
    name: string;
    remarks?: string;
    gdpr: boolean;
    adequacyDecision: boolean;
}

