import { HttpErrorResponse, HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { catchError, throwError } from 'rxjs';
import { ToastService } from 'src/app/services/toast.service';

export const errorInterceptor: HttpInterceptorFn = (req, next) => {
  var toastService = inject(ToastService);
  return next(req).pipe(
    catchError((error: HttpErrorResponse) => {
      let errorMessage = error.error?.message ?? error.message;
      if (error.status === 403) {
        errorMessage = 'Access Denied! You do not have permission to access this resource.';
      } else if (error.status === 404) {
        errorMessage = error.error.errorMessage ?? 'Resource Not Found!';
      } else if (error.status === 400) {
        errorMessage = error.error.errorMessage ?? 'Bad Request! If the error persists, please contact the administrator.';
      } else if (error.status === 401) {
        errorMessage = 'Unauthorized! Please login again.';
      } else if (error.status === 500) {
        errorMessage = 'Internal Server Error! If the error persists, please contact the administrator.';
      } else if (error.status === 413) {
        errorMessage = 'Payload Too Large!';
      }
      toastService.showError(errorMessage);
      return throwError(() => new Error(errorMessage));
    }),
  );
};
