import { Environment } from './environment.type';

export const environment: Environment = {
  production: true,
  name: 'dev',
  amplifyConfiguration: {
    aws_project_region: 'eu-central-1',
    aws_cognito_identity_pool_id: 'eu-central-1:55ca43e5-2215-4e07-b6be-086a5f1d9ade',
    aws_cognito_region: 'eu-central-1',
    aws_user_pools_id: 'eu-central-1_gjJh4Wm1L',
    aws_user_pools_web_client_id: '13lod9rom49pou5103vguervj4',
  },
};
