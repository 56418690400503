import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-assessment-status-card',
  templateUrl: './assessment-status-card.component.html',
  styleUrls: ['./assessment-status-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AssessmentStatusCardComponent {
  @Input() totalQuestions?: number = 10;
  @Input() statementsAnswered: number = 0;
  @Input() isAudit: boolean = false;

  getPercentage(): number {
    return this.statementsAnswered / (this.totalQuestions ?? 10);
  }
}
