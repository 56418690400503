<ngb-toast
  *ngFor="let toast of toastService.toasts"
  [class]="(!!toast.color ? 'bg-' + toast.color : 'bg-primary') + ' ' + toast.className || ''"
  [autohide]="toast.autohide || true"
  [delay]="toast.delay || 5000"
  (hidden)="toastService.remove(toast)"
>
  <div class="align-items-center show" role="alert" aria-live="assertive" aria-atomic="true" data-bs-autohide="false">
    <ng-template [ngIf]="isTemplate(toast)" [ngIfElse]="text">
      <ng-template [ngTemplateOutlet]="toast.textOrTpl"></ng-template>
    </ng-template>

    <ng-template #text>{{ toast.textOrTpl }}</ng-template>
  </div>
</ngb-toast>
