import { Directive, HostListener } from '@angular/core';

enum Theme {
  light = 'light',
  dark = 'dark',
}

@Directive({
  selector: '[appToggleTheme]',
})
export class ToggleThemeDirective {
  private body: HTMLBodyElement | any = document.querySelector('body');

  constructor() {
    //get local storage theme preference
    const theme = localStorage.getItem('theme');
    if (theme === Theme.dark && this.body.classList.contains('dark-mode') === false) {
      this.toggleTheme();
    }
  }

  @HostListener('click') toggleTheme(): void {
    if (this.body != !this.body) {
      this.body.classList.toggle('dark-mode');
      this.body.classList.remove('bg-img1');
      this.body.classList.remove('bg-img2');
      this.body.classList.remove('bg-img3');
      this.body.classList.remove('bg-img4');
    }
    if (this.body.classList.contains('dark-mode')) {
      //store the theme preference in local storage
      localStorage.setItem('theme', Theme.dark);
    } else {
      localStorage.setItem('theme', Theme.light);
    }
  }
}
