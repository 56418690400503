import { Component, EventEmitter, Input, OnInit, Output, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { toggleConfig } from 'ng-toggle-button';

@Component({
  selector: 'app-toggle-button',
  templateUrl: './toggle-button.component.html',
  styleUrls: ['./toggle-button.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ToggleButtonComponent),
      multi: true,
    },
  ],
})
export class ToggleButtonComponent implements OnInit, ControlValueAccessor {
  @Input() value?: boolean = false;
  @Input() labels: boolean | toggleConfig = false;
  @Input() size: 'sm' | 'md' | 'lg' = 'md';
  @Input() disabled: boolean = false;
  @Input() description?: string;
  @Input() cyTag?: string;

  @Output() changeEvent = new EventEmitter<boolean>();

  width!: number;
  height!: number;

  private sizeStringToWidthHeight = {
    sm: { width: 38, height: 20 },
    md: { width: 42, height: 22 },
    lg: { width: 55, height: 26 },
  };

  ngOnInit(): void {
    this.width = this.sizeStringToWidthHeight[this.size].width;
    this.height = this.sizeStringToWidthHeight[this.size].height;
  }

  change(): void {
    this.value = !this.value;
    this.onChange(this.value);
    this.changeEvent.emit(this.value);
  }

  onChange(value: boolean): void {
    this.value = value;
  }

  onTouched(): void {
    // dummy
  }

  writeValue(value: boolean): void {
    this.value = value;
    this.onChange(this.value);
  }

  registerOnChange(fn: (value: boolean) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
}
