<div class="app-header header sticky">
  <div class="container-fluid main-container">
    <div class="d-flex align-items-center">
      <a aria-label="Hide Sidebar" class="app-sidebar__toggle" data-bs-toggle="sidebar" (click)="toggleSidebar()">
        <i class="bi bi-list cursor-pointer" aria-hidden="true"></i>
      </a>

      <div class="d-flex order-lg-2 ms-auto header-right-icons header-search-icon">
        <button
          class="navbar-toggler navresponsive-toggler d-lg-none ms-auto collapsed"
          type="button"
          (click)="collapse.toggle()"
          [attr.aria-expanded]="!isCollapsed"
          aria-controls="collapseExample"
        >
          <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24" class="navbar-toggler-icon">
            <path d="M0 0h24v24H0V0z" fill="none"></path>
            <path
              d="M12 8c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z"
            ></path>
          </svg>
        </button>
        <div class="navbar navbar-collapse responsive-navbar p-0">
          <div
            class="collapse navbar-collapse"
            id="navbarSupportedContent-4"
            #collapse="ngbCollapse"
            [(ngbCollapse)]="isCollapsed"
            [horizontal]="true"
          >
            <div class="d-flex">
              <app-language-selector></app-language-selector>

              <div class="d-flex country">
                <a class="nav-link" appToggleTheme>
                  <span class="dark-layout"><i class="bi bi-moon" [ngbTooltip]="'header.darkTheme' | translate"></i></span>
                  <span class="light-layout"><i class="bi bi-brightness-high" [ngbTooltip]="'header.lightTheme' | translate"></i></span>
                </a>
              </div>
              <div class="dropdown d-flex">
                <a class="nav-link full-screen-link nav-link-bg" appFullscreen>
                  <i class="bi bi-fullscreen fullscreen-button" [ngbTooltip]="'header.fullscreen' | translate"></i>
                </a>
              </div>
              <div ngbDropdown class="dropdown profile-1 d-flex">
                <a ngbDropdownToggle href="javascript:void(0);" data-bs-toggle="dropdown" class="nav-link icon leading-none d-flex">
                  <app-avatar
                    *ngIf="user()?.firstName && user()?.lastName"
                    [fullName]="user().firstName + ' ' + user().lastName"
                    [color]="'warning'"
                  ></app-avatar>
                </a>
                <div ngbDropdownMenu class="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
                  <a ngbDropdownItem routerLink="/user">
                    <i class="dropdown-icon zmdi zmdi-edit"></i> {{ 'header.editProfile' | translate }}
                  </a>
                  <a ngbDropdownItem (click)="signOut()">
                    <i class="dropdown-icon mdi mdi-logout-variant"></i> {{ 'header.signOut' | translate }}
                  </a>
                </div>
              </div>
              <div class="d-flex flex-column cursor-default username-shower">
                <span class="mt-1">{{ username() }}</span>
                <small *ngIf="companyOfUser()?.businessId" class="text-muted d-flex business-id">
                  {{ companyOfUser()?.businessId }}

                  <i
                    class="bi bi-copy cursor-pointer ms-1"
                    [ngbTooltip]="'Copy to clipboard'"
                    (click)="copyTextToClipboard(companyOfUser()?.businessId ?? '')"
                  ></i
                ></small>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
