/**
 * Privacy-Plan WebApp
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type PartnershipStatus = 'NONE' | 'PENDING' | 'REVOKED' | 'DECLINED' | 'ACTIVE' | 'CANCELED';

export const PartnershipStatus = {
    None: 'NONE' as PartnershipStatus,
    Pending: 'PENDING' as PartnershipStatus,
    Revoked: 'REVOKED' as PartnershipStatus,
    Declined: 'DECLINED' as PartnershipStatus,
    Active: 'ACTIVE' as PartnershipStatus,
    Canceled: 'CANCELED' as PartnershipStatus
};

