import { Component, Input } from '@angular/core';
import { AssessmentDataDTO, CompanyDTO } from 'src/app/connectors';
import { UtilsService } from '../../services/utils.service';

@Component({
  selector: 'app-assessment-row',
  templateUrl: './assessment-row.component.html',
  styleUrls: ['./assessment-row.component.scss'],
})
export class AssessmentRowComponent {
  @Input() assessment!: AssessmentDataDTO;

  constructor(private utils: UtilsService) {}

  getInitials(companyName?: string): string {
    return this.utils.getInitials(companyName);
  }
}
