import { inject, NgModule } from '@angular/core';

import { RouterModule, Routes } from '@angular/router';
import { ContentLayoutComponent } from './shared/layout-components/layout/content-layout/content-layout.component';
import { AssessmentComponent } from './pages/assessment/assessment.component';
import { CompanyGuard } from './shared/guards/company.guard';
import { AdminGuard } from './shared/guards/admin.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/home',
    pathMatch: 'full',
  },
  // Vertical layout
  {
    path: '',
    component: ContentLayoutComponent,
    children: [
      { path: 'home', loadComponent: () => import('./pages/home/home.component').then(m => m.HomeComponent) },
      {
        path: 'customers',
        loadComponent: () => import('./pages/customers/customers.component').then(m => m.CustomersComponent),
      },
      {
        path: 'suppliers',
        loadComponent: () => import('./pages/suppliers/suppliers.component').then(m => m.SuppliersComponent),
      },
      {
        path: 'suppliers/:id',
        loadComponent: () => import('./pages/supplier-details/supplier-details.component').then(m => m.SupplierDetailsComponent),
      },
      {
        path: 'my-company',
        loadComponent: () => import('./pages/company/company.component').then(m => m.CompanyComponent),
      },
      { path: 'user', loadComponent: () => import('./pages/user/user.component').then(m => m.UserComponent) },
      {
        path: 'admin',
        loadComponent: () => import('./pages/admin/admin.component').then(m => m.AdminComponent),
        canActivate: [() => inject(AdminGuard).canActivate()],
      },
      {
        path: 'admin/questionnaire/:id/edit',
        loadComponent: () => import('./pages/questionnaire-edit/edit-questionnaire.component').then(m => m.EditQuestionnaireComponent),
        canActivate: [() => inject(AdminGuard).canActivate()],
      },
      {
        path: 'assessment',
        loadComponent: () => import('./pages/assessment/assessment.component').then(m => m.AssessmentComponent),
        canActivate: [() => inject(CompanyGuard).canActivate()],
        canDeactivate: [
          (component: AssessmentComponent) => {
            if (component.unsavedChanges) {
              alert('Unsaved changes!');
              return false;
            }
            return true;
          },
        ],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
