/**
 * Privacy-Plan WebApp
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { StorageItemDTO } from './storageItemDTO';
import { Answers } from './answers';
import { ReviewStatus } from './reviewStatus';
import { QuestionDTO } from './questionDTO';


export interface StatementDataDTO { 
    id?: string;
    answer?: Answers;
    commentOfUser?: string;
    commentOfAuditor?: string;
    attachments?: Array<StorageItemDTO>;
    changes?: boolean;
    reviewStatus?: ReviewStatus;
    question?: QuestionDTO;
    hasHistory?: boolean;
    assessmentId?: string;
}
export namespace StatementDataDTO {
}


