<footer class="footer">
  <div class="container">
    <div class="row align-items-center flex-row-reverse">
      <div class="col-md-12 col-sm-12 text-center d-flex justify-content-center align-items-center">
        <p class="m-0">Copyright © 2024</p>
        <a class="mx-1" href="javascript:void(0);">Processor Compliance.</a>
        <p class="m-0">{{ 'footer.allRightsReserved' | translate }}</p>
      </div>
    </div>
  </div>
</footer>
