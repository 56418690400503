import { MissingTranslationHandler, MissingTranslationHandlerParams } from '@ngx-translate/core';

export const translationNotFoundMessage = 'translation-not-found';

export class MissingTranslationHandlerImpl implements MissingTranslationHandler {
  handle(params: MissingTranslationHandlerParams): string {
    const key = params.key;
    return `${translationNotFoundMessage}[${key}]`;
  }
}

export function missingTranslationHandler(): MissingTranslationHandler {
  return new MissingTranslationHandlerImpl();
}

/*
    Languages codes are ISO_639-1 codes, see http://en.wikipedia.org/wiki/List_of_ISO_639-1_codes
    They are written in English to avoid character encoding issues (not a perfect solution)
*/
export const LANGUAGES: string[] = ['en', 'de'];

export type ALL_LANGUAGES = 'en' | 'de';

export const LANGUAGE_NAMES: Record<ALL_LANGUAGES, string> = {
  en: 'English',
  de: 'Deutsch',
};

export const LANGUAGE_FLAG_URLS: Record<ALL_LANGUAGES, string> = {
  de: './assets/img/germany_flag.jpg',
  en: './assets/img/us_flag.jpg',
};
