/**
 * Privacy-Plan WebApp
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type AssessmentStatus = 'NONE' | 'EDITING' | 'TRANSFERRED_TO_AUDIT' | 'REVIEW' | 'EDITING_RECALL' | 'APPROVED' | 'RELEASED_TO_CONTROLLER';

export const AssessmentStatus = {
    None: 'NONE' as AssessmentStatus,
    Editing: 'EDITING' as AssessmentStatus,
    TransferredToAudit: 'TRANSFERRED_TO_AUDIT' as AssessmentStatus,
    Review: 'REVIEW' as AssessmentStatus,
    EditingRecall: 'EDITING_RECALL' as AssessmentStatus,
    Approved: 'APPROVED' as AssessmentStatus,
    ReleasedToController: 'RELEASED_TO_CONTROLLER' as AssessmentStatus
};

