import { Component, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { StateStorageService } from '../../services/state-storage.service';
import { ALL_LANGUAGES, LANGUAGE_FLAG_URLS, LANGUAGE_NAMES, LANGUAGES } from '../../language/translation.config';
import { GlobalStore } from '../../services/global.store';
import { Language } from '../../../connectors';

@Component({
  selector: 'app-language-selector',
  templateUrl: './language-selector.component.html',
  styleUrls: ['./language-selector.component.scss'],
})
export class LanguageSelectorComponent implements OnInit {
  @Input() persistLanguageToAuthenticatedUser: boolean = false;
  @Input() labelKey?: string;

  @Input() language: ALL_LANGUAGES = 'de';

  @Output() triggerLanguageChange = new EventEmitter<ALL_LANGUAGES>();
  store = inject(GlobalStore);

  languages = LANGUAGES as ALL_LANGUAGES[];
  languageNames = LANGUAGE_NAMES;
  languageFlagUrls = LANGUAGE_FLAG_URLS;

  currentLanguageFlagUrl!: string;

  constructor(
    private translateService: TranslateService,
    private stateStorageService: StateStorageService,
  ) {}

  ngOnInit(): void {
    // Find out the default language (currently used)
    this.language = (this.stateStorageService.getLocale() || this.translateService.currentLang) as ALL_LANGUAGES;
    this.currentLanguageFlagUrl = this.getLanguageUrl(this.language);
    this.store.updateUserLanguage(this.language.toUpperCase() as Language);
  }

  changeLanguage(languageKey: ALL_LANGUAGES): void {
    this.stateStorageService.storeLocale(languageKey);
    this.translateService.use(languageKey);

    this.currentLanguageFlagUrl = this.getLanguageUrl(languageKey);
    this.triggerLanguageChange.emit(languageKey);
    this.store.updateUserLanguage(languageKey.toUpperCase() as Language);
  }

  getLanguageUrl(lang: ALL_LANGUAGES): string {
    return this.languageFlagUrls[lang];
  }

  getLanguageName(lang: ALL_LANGUAGES): string {
    return this.languageNames[lang];
  }
}
