import { Component, Input } from '@angular/core';
import { BootstrapColors } from 'src/app/shared/models/colors.model';

@Component({
  selector: 'app-avatar-company',
  templateUrl: './avatar-company.component.html',
  styleUrls: ['./avatar-company.component.scss'],
})
export class AvatarCompanyComponent {
  @Input() label!: string;
  @Input() color: BootstrapColors = 'warning';
}
