import { inject, Injectable, Injector } from '@angular/core';
import { Amplify } from 'aws-amplify';
import { GlobalStore } from './global.store';
import { AuthenticatorService } from '@aws-amplify/ui-angular';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  constructor(public authenticator: AuthenticatorService) {}

  store = inject(GlobalStore);

  initAuth(): void {
    Amplify.configure(environment.amplifyConfiguration);
    this.authenticator.subscribe(authState => {
      if (authState.authStatus === 'authenticated') {
        const user = this.authenticator.user;
        if (user) {
          this.store.loadApplicationForUser({ userId: user.attributes!['sub'], userMail: user.attributes!['email'] });
        }
      }
    });
  }
}

export function handleInitAuth(injector: Injector): () => void {
  return (): void => {
    return injector.get(AuthenticationService).initAuth();
  };
}
