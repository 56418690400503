/**
 * Privacy-Plan WebApp
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AssessmentStatus } from './assessmentStatus';


export interface AssessmentDataDTO { 
    id?: string;
    questionnaireVersion?: number;
    totalQuestions?: number;
    answeredQuestions?: number;
    companyName?: string;
    companyId?: string;
    auditorId?: string;
    auditorFirstName?: string;
    auditorLastName?: string;
    status?: AssessmentStatus;
}
export namespace AssessmentDataDTO {
}


