import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterComponent } from './layout-components/footer/footer.component';
import { HeaderComponent } from './layout-components/header/header.component';
import { PageHeaderComponent } from './layout-components/page-header/page-header.component';
import { SidebarComponent } from './layout-components/sidebar/sidebar.component';
import { TabToTopComponent } from './layout-components/tab-to-top/tab-to-top.component';
import { ContentLayoutComponent } from './layout-components/layout/content-layout/content-layout.component';
import { ErrorLayoutComponent } from './layout-components/layout/error-layout/error-layout.component';
import { RouterModule } from '@angular/router';
import { ElementCardHeaderComponent } from './layout-components/element-card-header/element-card-header.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ToggleThemeDirective } from './directives/toggle-theme.directive';
import { FullscreenDirective } from './directives/fullscreen-toggle.directive';
import { HoverEffectSidebarDirective } from './directives/hover-effect-sidebar.directive';
import { PageHeader2Component } from './layout-components/page-header2/page-header2.component';
import {
  NgbAccordionModule,
  NgbCollapseModule,
  NgbModule,
  NgbNavModule,
  NgbToastModule,
  NgbTooltipModule,
} from '@ng-bootstrap/ng-bootstrap';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { SaveButtonComponent } from './layout-components/save-button/save-button.component';
import { LogoComponent } from './layout-components/logo/logo.component';
import { InputComponent } from './layout-components/input/input.component';
import { AvatarCompanyComponent } from './layout-components/avatar-company/avatar-company.component';
import { AvatarComponent } from './layout-components/avatar/avatar.component';
import { BadgeComponent } from './layout-components/badge/badge.component';
import { AssessmentStatusCardComponent } from './layout-components/assessment-status-card/assessment-status-card.component';
import { AssessmentStatusBadgeComponent } from './layout-components/badge/assessment-status-badge/assessment-status-badge.component';
import { AssessmentRowComponent } from './layout-components/assessment-row/assessment-row.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ToggleButtonComponent } from './layout-components/toggle-button/toggle-button.component';
import { NgToggleModule } from 'ng-toggle-button';
import { NgSelectModule } from '@ng-select/ng-select';
import { ToastComponent } from './layout-components/toast/toast.component';
import { LoaderComponent } from './layout-components/loader/loader.component';
import { NotificationComponent } from './layout-components/notification/notification.component';
import { MatInputModule } from '@angular/material/input';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { QuillEditorComponent } from 'ngx-quill';
import { TranslateModule } from '@ngx-translate/core';
import { LanguageSelectorComponent } from './components/language-selector/language-selector.component';

const COMPONENTS = [
  SaveButtonComponent,
  LanguageSelectorComponent,
  LogoComponent,
  InputComponent,
  AvatarCompanyComponent,
  AvatarComponent,
  BadgeComponent,
  AssessmentStatusCardComponent,
  AssessmentStatusBadgeComponent,
  AssessmentRowComponent,
  ToggleButtonComponent,
  ToastComponent,
];

const MODULES = [
  FormsModule,
  ReactiveFormsModule,
  NgbTooltipModule,
  NgbToastModule,
  NgbNavModule,
  NgbAccordionModule,
  DragDropModule,
  NgToggleModule,
  NgSelectModule,
  LoaderComponent,
  CommonModule,
  TranslateModule,
];

@NgModule({
  declarations: [
    FooterComponent,
    HeaderComponent,
    PageHeaderComponent,
    SidebarComponent,
    TabToTopComponent,
    ContentLayoutComponent,
    ElementCardHeaderComponent,
    ErrorLayoutComponent,
    ToggleThemeDirective,
    FullscreenDirective,
    HoverEffectSidebarDirective,
    PageHeader2Component,
    ...COMPONENTS,
  ],
  imports: [
    RouterModule,
    NgbModule,
    NgScrollbarModule,
    NgScrollbarModule,
    NgbCollapseModule,
    ...MODULES,
    NotificationComponent,
    MatInputModule,
    NgxSkeletonLoaderModule.forRoot({
      theme: {
        extendsFromRoot: true,
        height: '30px',
      },
    }),
    QuillEditorComponent,
    TranslateModule,
  ],

  exports: [PageHeaderComponent, PageHeader2Component, ElementCardHeaderComponent, ...COMPONENTS, ...MODULES, FullscreenDirective],
  providers: [],
})
export class SharedModule {}
