import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { AssessmentStatus } from 'src/app/connectors';
import { BootstrapColors } from 'src/app/shared/models/colors.model';

@Component({
  selector: 'app-assessment-status-badge',
  templateUrl: './assessment-status-badge.component.html',
  styleUrls: ['./assessment-status-badge.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AssessmentStatusBadgeComponent implements OnInit, OnChanges {
  @Input() status?: AssessmentStatus;

  ngOnInit(): void {
    this.setAssessmentColor();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes['status']) {
      this.setAssessmentColor();
    }
  }

  color: BootstrapColors = 'primary';

  setAssessmentColor(): void {
    switch (this.status) {
      case 'NONE':
        this.color = 'dark';
        break;
      case 'EDITING':
      case 'EDITING_RECALL':
      case 'REVIEW':
        this.color = 'warning';
        break;
      case 'APPROVED':
      case 'RELEASED_TO_CONTROLLER':
        this.color = 'success';
        break;
      default:
        this.color = 'primary';
        break;
    }
  }
}
