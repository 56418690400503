<div class="d-flex justify-content-between py-1">
  <div class="d-flex">
    <app-avatar-company class="me-2" [label]="getInitials(assessment.companyName)" [color]="'secondary'"></app-avatar-company>
    <div class="d-flex flex-column">
      <div class="d-flex">
        <span>{{ assessment.companyName }}</span>
        <app-assessment-status-badge [status]="assessment.status" class="ms-1"></app-assessment-status-badge>
      </div>
      <small class="text-muted"> {{ ('admin.version' | translate) + assessment.questionnaireVersion }}</small>
    </div>
  </div>
  <div class="d-flex">
    <ng-content></ng-content>
  </div>
</div>
