import { Component, DestroyRef, inject } from '@angular/core';
import { Authority, Menu, NavService } from '../../services/nav.service';
import { UserActions, UserStatusDTO } from 'src/app/connectors';
import { GlobalStore } from '../../services/global.store';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { DeepSignal } from '@ngrx/signals/src/deep-signal';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent {
  menuItems: Menu[] = [];

  get showCompanyMenu() {
    return !this.userStatus()?.requiredActions?.includes(UserActions.Profile) && (this.userStatus()?.user || this.userStatus()?.auditor);
  }

  get showUserMenuEntries() {
    return this.userStatus()?.requiredActions?.length == 0 && this.userStatus()?.user;
  }

  get isAdmin(): boolean {
    return this.userStatus()?.admin!;
  }

  store = inject(GlobalStore);
  userStatus: DeepSignal<UserStatusDTO> = this.store.userStatus;

  constructor(
    private navServices: NavService,
    private destroyRef: DestroyRef,
  ) {
    toObservable(this.store.userStatus)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.populateMenuItems();
      });
  }

  sidebarClose() {
    if (this.navServices.collapseSidebar === true) {
      document.querySelector('.app')?.classList.remove('sidenav-toggled');
      this.navServices.collapseSidebar = false;
    }
  }

  private populateMenuItems(): void {
    this.navServices.items.subscribe((menuItems: any) => {
      const shownUserMenus = menuItems.filter(
        (item: Menu) => item.showForAuthority == null || this.shouldShowMenuItemForUser(item.showForAuthority),
      );
      // Implement rights check here.
      this.menuItems = shownUserMenus;
    });
  }

  private shouldShowMenuItemForUser(showForAuthority: Authority[]): boolean {
    if (showForAuthority?.length == 0) {
      return true;
    }
    return showForAuthority.some(authority => {
      switch (authority) {
        case Authority.Admin:
          return this.isAdmin;
        case Authority.User:
          return this.showUserMenuEntries;
        case Authority.Auditor:
          return this.userStatus()?.auditor!;
        case Authority.Company:
          return this.showCompanyMenu;
        default:
          return false;
      }
    });
  }
}
