import { Injectable, TemplateRef } from '@angular/core';
import { NgbToastOptions } from '@ng-bootstrap/ng-bootstrap/toast/toast-config';
import { BootstrapColors } from '../shared/models/colors.model';
import { TranslateService } from '@ngx-translate/core';

/**
 * Ng Bootstrap Toast Service
 *
 * Reference: https://ng-bootstrap.github.io/#/components/toast/examples
 */
@Injectable({ providedIn: 'root' })
export class ToastService {
  toasts: any[] = [];

  constructor(private translateService: TranslateService) {}

  showSuccess(textKey: string): void {
    const translatedText = this.translateService.instant(textKey);
    this.show(translatedText, { className: 'bg-success text-light', delay: 10000 });
  }

  showError(textKey: string): void {
    this.show(textKey, { className: 'bg-danger text-light', delay: 10000 });
  }

  show(textOrTpl: string | TemplateRef<any>, options: ToastOptions = {}): void {
    this.toasts.push({ textOrTpl, ...options });
  }

  remove(toast: any): void {
    this.toasts = this.toasts.filter(t => t !== toast);
  }

  clear(): void {
    this.toasts.splice(0, this.toasts.length);
  }
}

export interface ToastOptions extends NgbToastOptions {
  color?: BootstrapColors;
  className?: string;
}
