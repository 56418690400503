import { Injectable } from '@angular/core';
import { CompanyDTO } from 'src/app/connectors';

@Injectable({
  providedIn: 'root',
})
export class UtilsService {
  constructor() {}

  getInitials(companyName?: string): string {
    if (!companyName) {
      return '';
    }
    const firstChar = companyName.charAt(0).toUpperCase() ?? '';
    const secondChar = companyName.charAt(1).toUpperCase() ?? '';
    return firstChar + secondChar;
  }

  getAddressStringFromCompany(company: CompanyDTO | undefined): string {
    if (!company) {
      return '';
    }
    return `${company.street} ${company.zipCode} ${company.city}, ${company.country?.name}`;
  }
}
