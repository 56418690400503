import { HttpInterceptorFn } from '@angular/common/http';
import { Auth } from 'aws-amplify';
import { from, switchMap } from 'rxjs';

export const authInterceptor: HttpInterceptorFn = (req, next) => {
  if (!req.url.startsWith('/assets')) {
    return from(Auth.currentSession()).pipe(
      switchMap(session => {
        const token = session.getAccessToken().getJwtToken();
        const headers = req.headers.set('Authorization', `Bearer ${token}`);
        const authReq = req.clone({ headers });
        return next(authReq);
      }),
    );
  }
  return next(req);
};
