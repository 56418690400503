/**
 * Privacy-Plan WebApp
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CountryDTO } from './countryDTO';
import { InvoiceAddressDTO } from './invoiceAddressDTO';


export interface CompanyDTO { 
    id?: string;
    businessId?: string;
    name?: string;
    street?: string;
    zipCode?: string;
    city?: string;
    country?: CountryDTO;
    registryCourt?: string;
    commercialRegister?: string;
    serviceUnit?: string;
    vatId?: string;
    serviceDescription?: string;
    invoiceAddress?: InvoiceAddressDTO;
    userEmail?: string;
}

