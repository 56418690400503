<div class="page-header d-flex justify-content-between">
  <div>
    <h1 class="page-title">{{ title }}</h1>
    <ol class="breadcrumb">
      <li class="breadcrumb-item" *ngFor="let item of items">
        <a *ngIf="!item.path" href="javascript:void(0);">{{ item.label }}</a>
        <a *ngIf="item.path" [routerLink]="item.path">{{ item.label }}</a>
      </li>
      <li class="breadcrumb-item active" aria-current="page">
        {{ active_item }}
      </li>
    </ol>
  </div>
  <div>
    <ng-content></ng-content>
  </div>
</div>
