/**
 * Privacy-Plan WebApp
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type StatementChangeType = 'AUDITOR_COMMENT' | 'USER_COMMENT' | 'REVIEW_STATUS' | 'ANSWER' | 'FILE_UPLOAD';

export const StatementChangeType = {
    AuditorComment: 'AUDITOR_COMMENT' as StatementChangeType,
    UserComment: 'USER_COMMENT' as StatementChangeType,
    ReviewStatus: 'REVIEW_STATUS' as StatementChangeType,
    Answer: 'ANSWER' as StatementChangeType,
    FileUpload: 'FILE_UPLOAD' as StatementChangeType
};

