<!-- PAGE-HEADER -->
<div class="page-header">
  <div>
    <h1 class="page-title">{{ title }}</h1>
    <ol class="breadcrumb">
      <li class="breadcrumb-item" *ngFor="let item of items">
        <a href="javascript:void(0);">{{ item }}</a>
      </li>
      <li class="breadcrumb-item active" aria-current="page">
        {{ active_item }}
      </li>
    </ol>
  </div>
  <div class="ms-auto pageheader-btn">
    <a href="javascript:void(0);" class="btn btn-primary btn-icon me-2">
      <span> <i class="fe fe-shopping-cart"></i> Add order </span>
    </a>
    <a href="javascript:void(0);" class="btn btn-primary btn-icon me-2">
      <span> <i class="fe fe-plus"></i> Add User </span>
    </a>
  </div>
</div>
<!-- PAGE-HEADER END -->
