<div class="sticky">
  <!--APP-SIDEBAR-->
  <div class="app-sidebar__overlay main-sidebar main-sidebar-sticky sidemenu-scroll" (click)="sidebarClose()"></div>
  <!-- Sidemenu -->
  <div class="app-sidebar active">
    <div class="side-header">
      <app-logo></app-logo>
    </div>
    <div class="main-sidemenu is-expanded">
      <ul class="side-menu open flex-nowrap">
        <!-- 1st Level Menu -->
        <li
          class="side-item"
          *ngFor="let menuItem of menuItems"
          [ngClass]="{
            slide: menuItem.title,
            active: menuItem.selected,
            'side-item': menuItem.type,
            show: menuItem.active,
            'is-expanded': menuItem.active
          }"
        >
          <h3 class="sub-category" *ngIf="menuItem.headTitle">
            {{ menuItem.headTitle | translate }}
          </h3>
          <!-- has-Link -->
          <a
            class="side-menu__item has__link"
            [routerLink]="!menuItem.type ? null : [menuItem.path]"
            *ngIf="menuItem.type === 'link'"
            routerLinkActive="active"
          >
            <span class="side-menu__icon">
              <i class="bi-{{ menuItem.icon }} "></i>
            </span>
            <span class="side-menu__label" *ngIf="menuItem.title">{{ menuItem.title | translate }}</span>
            <span class="badge side-badge bg-{{ menuItem.badgeClass }} ">{{ menuItem.badgeValue }}</span>
          </a>
        </li>
      </ul>
    </div>
  </div>
  <!-- End Sidemenu -->
</div>
