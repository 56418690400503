import { APP_INITIALIZER, Injector, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClient, HttpClientModule, provideHttpClient, withInterceptors } from '@angular/common/http';
import { ToastrModule } from 'ngx-toastr';
import { LayoutService } from './shared/services/layout.service';
import { SharedModule } from './shared/shared.module';
import { AmplifyAuthenticatorModule } from '@aws-amplify/ui-angular';
import { authInterceptor } from './shared/interceptors/auth.interceptor';
import { handleInitAuth } from './shared/services/authentication.service';
import { errorInterceptor } from './shared/interceptors/error.interceptor';
import { QuillModule } from 'ngx-quill';
import { QUILL_CONFIG } from './shared/layout-components/input/quill.config';
import { TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslationModule } from './shared/language/translation.module';

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AmplifyAuthenticatorModule,
    ToastrModule.forRoot(),
    SharedModule,
    QuillModule.forRoot({ modules: QUILL_CONFIG }),
    TranslationModule,
    TranslateModule,
  ],
  providers: [
    LayoutService,
    {
      provide: APP_INITIALIZER,
      useFactory: handleInitAuth,
      multi: true,
      deps: [Injector],
    },
    provideHttpClient(withInterceptors([authInterceptor, errorInterceptor])),
  ],
  bootstrap: [AppComponent],
  schemas: [],
})
export class AppModule {
  constructor() {}
}
