<!-- Light Mode: Visible when menu is NOT collapsed -->
<a href="/" class="logo light-logo1 header-brand-img">
    <img alt="Processor Compliance logo" src="assets/img/pc-logo.png">
</a>

<!-- Light Mode: Visible when menu is collapsed -->
<a href="/" class="logo light-logo header-brand-img">
    <img alt="Processor Compliance logo" src="assets/img/pc-logo-small.png">
</a>

<!--  -->
<!-- Dark Mode: Visible when menu is NOT collapsed -->
<a href="/" class="logo desktop-logo header-brand-img">
    <img alt="Processor Compliance logo" src="assets/img/pc-logo-white.png">
</a>

<!-- Dark Mode: Visible when menu is collapsed -->
<a href="/" class="logo toggle-logo header-brand-img">
    <img alt="Processor Compliance logo" src="assets/img/pc-logo-white-small.png">
</a>
