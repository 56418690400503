import { NgModule } from '@angular/core';
import { MissingTranslationHandler, TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { missingTranslationHandler } from './translation.config';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { StateStorageService } from '../services/state-storage.service';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  imports: [
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useFactory: missingTranslationHandler,
      },
    }),
  ],
})
export class TranslationModule {
  constructor(
    private translateService: TranslateService,
    private stateStorageService: StateStorageService,
  ) {
    const langKey = this.stateStorageService.getLocale() ?? 'en';

    this.translateService.setDefaultLang(langKey);
    this.translateService.use(langKey);
  }
}
