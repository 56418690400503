export * from './adminResource.service';
import { AdminResourceService } from './adminResource.service';
export * from './assessmentResource.service';
import { AssessmentResourceService } from './assessmentResource.service';
export * from './companyResource.service';
import { CompanyResourceService } from './companyResource.service';
export * from './countryResource.service';
import { CountryResourceService } from './countryResource.service';
export * from './logsResource.service';
import { LogsResourceService } from './logsResource.service';
export * from './pingResource.service';
import { PingResourceService } from './pingResource.service';
export * from './questionnaireResource.service';
import { QuestionnaireResourceService } from './questionnaireResource.service';
export * from './statementResource.service';
import { StatementResourceService } from './statementResource.service';
export * from './storageResource.service';
import { StorageResourceService } from './storageResource.service';
export * from './userResource.service';
import { UserResourceService } from './userResource.service';
export const APIS = [AdminResourceService, AssessmentResourceService, CompanyResourceService, CountryResourceService, LogsResourceService, PingResourceService, QuestionnaireResourceService, StatementResourceService, StorageResourceService, UserResourceService];
