import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { BootstrapColors } from 'src/app/shared/models/colors.model';

@Component({
  selector: 'app-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AvatarComponent implements OnInit {
  @Input() label!: string;
  @Input() fullName: string | undefined;
  @Input() color: BootstrapColors = 'warning';
  @Input() isSquared = false;

  ngOnInit(): void {
    if (this.fullName) {
      this.label = this.fullName
        .split(' ')
        .map(n => n[0])
        .join('')
        .toUpperCase();
    }
  }
}
