import { patchState, signalStore, withMethods, withState } from '@ngrx/signals';
import {
  AssessmentDataDTO,
  AssessmentResourceService,
  CompanyDTO,
  CompanyResourceService,
  CountryDTO,
  CountryResourceService,
  Language,
  UserDTO,
  UserResourceService,
  UserStatusDTO,
} from 'src/app/connectors';
import { UserDashboard } from '../models/dashboard.model';
import { inject } from '@angular/core';
import { ToastService } from '../../services/toast.service';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { combineLatest, pipe, switchMap, tap } from 'rxjs';
import { tapResponse } from '@ngrx/component-store';
import { AdminResourceService } from '../../connectors/api/adminResource.service';
import { AdminDashboardDTO } from '../../connectors/model/adminDashboardDTO';
import { Router } from '@angular/router';

const initialState: GlobalState = {
  applicationLoading: false,
  username: '',
  userId: '',
  userLanguage: Language.En,
  user: {
    email: '',
    firstName: '',
    lastName: '',
    department: '',
    role: '',
  },
  roles: [],
  userStatus: {},
  companyOfUser: {},
  isLoadingUser: true,
  countries: [],
  auditorDashboard: [],
  adminDashboard: {},
  userDashboard: {},
};
export const GlobalStore = signalStore(
  { providedIn: 'root' },
  withState<GlobalState>(initialState),
  withMethods(
    (
      store,
      userResourceService = inject(UserResourceService),
      router = inject(Router),
      companyResourceService = inject(CompanyResourceService),
      countryService = inject(CountryResourceService),
      assessmentService = inject(AssessmentResourceService),
      adminService = inject(AdminResourceService),
      toast = inject(ToastService),
    ) => {
      function updateCompanyOfUser(companyOfUser: CompanyDTO): void {
        patchState(store, { companyOfUser });
      }

      function updateUser(user: UserDTO): void {
        patchState(store, { user });
      }

      function updateApplicationLoading(applicationLoading: boolean) {
        patchState(store, { applicationLoading });
      }

      function updateUserStatus(userStatus: UserStatusDTO): void {
        patchState(store, { userStatus });
      }

      function updateUserLanguage(userLanguage: Language): void {
        patchState(store, { userLanguage });
      }

      function updateRoles(userStatus: UserStatusDTO): void {
        patchState(store, { roles: mapRoles(userStatus) });
      }

      function loadMoreAuditorDashboard(index: number): void {
        const dashboardCopy = store.auditorDashboard();
        const targetItem = dashboardCopy.find(item => item.index === index);
        if (targetItem) {
          targetItem.size = targetItem.size + 5;
          patchState(store, {
            auditorDashboard: dashboardCopy,
          });
        }
      }

      function loadDashboard(userStatus: UserStatusDTO, companyOfUser: CompanyDTO): void {
        if (userStatus.user && !companyOfUser?.id) {
          patchState(store, { applicationLoading: false });
        }
        if (userStatus.user && companyOfUser?.id) {
          loadUserDashboardData();
        }
        if (userStatus.auditor) {
          loadAuditorDashboardData();
        }
        if (userStatus.admin) {
          loadAdminDashboard();
        }
      }

      const loadApplicationForUser = rxMethod<{ userId: string; userMail: string }>(
        pipe(
          tap(() => patchState(store, { applicationLoading: true })),
          switchMap(({ userId, userMail }) =>
            combineLatest([
              userResourceService.getStatus(userId),
              userResourceService.getUserById(userId),
              companyResourceService.getByUserId(userId),
              countryService.getAllCountries(),
            ]).pipe(
              tapResponse({
                next: ([userStatus, user, companyOfUser, countries]) => {
                  patchState(store, {
                    userStatus,
                    roles: mapRoles(userStatus),
                    user,
                    userId,
                    username: userMail,
                    companyOfUser: companyOfUser ? companyOfUser : {},
                    countries: sortCountries(countries),
                  });
                  router.navigate(['/home']);
                },
                error: () => toast.showError('Error fetching user data. Please try again or contact the administrator.'),
              }),
              tapResponse({
                next: ([userStatus, user, companyOfUser]) => {
                  loadDashboard(userStatus, companyOfUser);
                },
                error: console.error,
              }),
            ),
          ),
        ),
      );

      const loadUserStatus = rxMethod<void>(
        pipe(
          tap(() => patchState(store, { isLoadingUser: true })),
          switchMap(() =>
            userResourceService.getStatus(store.userId()).pipe(
              tapResponse({
                next: userStatus => patchState(store, { userStatus, isLoadingUser: false }),
                error: console.error,
              }),
            ),
          ),
        ),
      );
      const loadUser = rxMethod<void>(
        pipe(
          switchMap(() =>
            userResourceService.getUserById(store.userId()).pipe(
              tapResponse({
                next: user => patchState(store, { user, isLoadingUser: false }),
                error: () => patchState(store, { isLoadingUser: false }),
              }),
            ),
          ),
        ),
      );
      const loadCompanyOfUser = rxMethod<void>(
        pipe(
          switchMap(() =>
            companyResourceService.getByUserId(store.userId()).pipe(
              tapResponse({
                next: companyOfUser => {
                  patchState(store, {
                    applicationLoading: false,
                    companyOfUser: companyOfUser ? companyOfUser : {},
                  });
                  loadUserDashboardData();
                },
                error: console.error,
              }),
            ),
          ),
        ),
      );
      const loadCountries = rxMethod<{}>(
        pipe(
          switchMap(params =>
            countryService.getAllCountries().pipe(
              tapResponse({
                next: countries => patchState(store, { countries }),
                error: console.error,
              }),
            ),
          ),
        ),
      );
      const loadUserDashboardData = rxMethod<void>(
        pipe(
          switchMap(() =>
            combineLatest([
              companyResourceService.getCompanyMeta(store.companyOfUser().id!),
              assessmentService.getAssessmentForCompany(store.companyOfUser().id!),
            ]).pipe(
              tapResponse({
                next: ([companyMeta, assessmentData]) => {
                  patchState(store, { userDashboard: { companyMeta, assessmentData }, applicationLoading: false });
                },
                error: () => toast.showError('Error fetching user data. Please try again or contact the administrator.'),
              }),
            ),
          ),
        ),
      );
      const loadAdminDashboard = rxMethod<void>(
        pipe(
          switchMap(() =>
            adminService.getAdminDashboard().pipe(
              tapResponse({
                next: adminDashboard => {
                  patchState(store, { adminDashboard, applicationLoading: false });
                },
                error: () => toast.showError('Error fetching user data. Please try again or contact the administrator.'),
              }),
            ),
          ),
        ),
      );
      const loadAuditorDashboardData = rxMethod<void>(
        pipe(
          switchMap(() =>
            assessmentService.getAssessmentsForAuditorDashboard(store.userId()).pipe(
              tapResponse({
                next: auditorDashboard => {
                  patchState(store, {
                    auditorDashboard: [
                      {
                        label: 'Transferred to audit',
                        index: 0,
                        size: 5,
                        items: auditorDashboard.assessmentsThatCanBePicked ?? [],
                      },
                      {
                        label: 'To audit',
                        index: 1,
                        size: 5,
                        items: auditorDashboard.assignedToAndNotApprovedYet ?? [],
                      },
                      {
                        label: 'Awaiting for client',
                        index: 2,
                        size: 5,
                        items: auditorDashboard.assignedToAndInRecall ?? [],
                      },
                      { label: 'Finished', index: 3, size: 5, items: auditorDashboard.assignedToAndApproved ?? [] },
                    ],
                    applicationLoading: false,
                  });
                },
                error: () => toast.showError('Error fetching user data. Please try again or contact the administrator.'),
              }),
            ),
          ),
        ),
      );
      return {
        updateCompanyOfUser,
        updateUser,
        updateApplicationLoading,
        updateUserStatus,
        updateRoles,
        updateUserLanguage,
        loadApplicationForUser,
        loadUserStatus,
        loadUser,
        loadCompanyOfUser,
        loadCountries,
        loadUserDashboardData,
        loadDashboard,
        loadAuditorDashboardData,
        loadAdminDashboard,
        loadMoreAuditorDashboard,
      };
    },
  ),
);
// Function to sort countries
const sortCountries = (countries: CountryDTO[]): CountryDTO[] => {
  const EUROPEAN_COUNTRY_CODES = [
    'DE',
    'FR',
    'IT',
    'ES',
    'PL',
    'RO',
    'NL',
    'BE',
    'GR',
    'CZ',
    'PT',
    'HU',
    'SE',
    'AT',
    'BG',
    'DK',
    'FI',
    'SK',
    'IE',
    'HR',
    'LT',
    'SI',
    'LV',
    'EE',
    'CY',
    'LU',
    'MT',
  ];
  return countries.sort((a, b) => {
    const aIsEuropean = EUROPEAN_COUNTRY_CODES.includes(a.code);
    const bIsEuropean = EUROPEAN_COUNTRY_CODES.includes(b.code);

    if (aIsEuropean && !bIsEuropean) return -1;
    if (!aIsEuropean && bIsEuropean) return 1;
    return a.name.localeCompare(b.name); // Sort alphabetically by name if both are in the same group
  });
};

const mapRoles = (userStatus: UserStatusDTO): ROLE[] => {
  let roles: ROLE[] = [];
  if (userStatus?.user) {
    roles.push(ROLE.User);
  }
  if (userStatus?.auditor) {
    roles.push(ROLE.Auditor);
  }
  if (userStatus?.admin) {
    roles.push(ROLE.Admin);
  }
  return roles;
};

interface GlobalState {
  applicationLoading: boolean;
  username: string;
  userId: string;
  userLanguage: Language;
  userStatus: UserStatusDTO;
  companyOfUser: CompanyDTO;
  roles: ROLE[];
  user: UserDTO;
  isLoadingUser: boolean;
  countries: CountryDTO[];
  auditorDashboard: AuditorDashboardItem[];
  userDashboard: UserDashboard;
  adminDashboard: AdminDashboardDTO;
}

export enum ROLE {
  Auditor = 'Auditor',
  User = 'User',
  Admin = 'Admin',
}

export interface AuditorDashboardItem {
  label: string;
  index: number;
  items: Array<AssessmentDataDTO>;
  size: number;
}
