import { Component, inject, Signal } from '@angular/core';
import { GlobalStore } from './shared/services/global.store';
import { environment } from '../environments/environment';
import { AuthenticatorService } from '@aws-amplify/ui-angular';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'Privacyplan';

  public formFields = {
    signIn: {
      username: {
        labelHidden: false,
        placeholder: 'Enter your Email',
        isRequired: true,
        label: 'Email:',
      },
    },
    signUp: {
      username: {
        labelHidden: false,
        placeholder: 'Enter your Email',
        isRequired: true,
        label: 'Email:',
      },
    },
  };
  store = inject(GlobalStore);
  applicationLoading: Signal<boolean> = this.store.applicationLoading;

  constructor(public authenticator: AuthenticatorService) {
    console.log('Application environment: ', environment.name);
  }
}
