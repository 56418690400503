import { KeyValue } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  forwardRef,
  inject,
  Inject,
  Injector,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  FormControl,
  FormControlDirective,
  FormControlName,
  FormGroupDirective,
  NG_VALUE_ACCESSOR,
  NgControl,
  NgModel,
  Validators,
} from '@angular/forms';
import { Subject, takeUntil, tap } from 'rxjs';
import { GlobalStore } from '../../services/global.store';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputComponent),
      multi: true,
    },
  ],
})
export class InputComponent implements OnInit, OnChanges, OnDestroy {
  @Input() label?: string;
  @Input() placeholder: string = '';
  @Input() isRequired = false;
  @Input() isDisabled = false;
  @Input() value: any = '';
  @Input() type: InputTypes = 'text';
  @Input() hideErrors = false;

  // Select-specific props
  @Input() bindLabel: string = 'value';
  @Input() bindValue?: string;
  /**
   * Dropdown items. Should be by default a key-value pair. If the props are called differently, use bindLabel and bindValue.
   */
  @Input() items?: KeyValue<string, string>[] | any;

  /**
   * Rows for textarea
   */
  @Input() rows: number = 3;

  /**
   * Name for connecting radios
   */
  @Input() name: string = '';
  /**
   * Radios and checkboxes: Whether is checked or not
   */
  @Input() checked: boolean = false;

  /**
   * Native HTML autocomplete for Input fields.
   */
  @Input() autocomplete: boolean = true;

  @Output() readonly changeRadio = new EventEmitter<Event>();

  public control!: FormControl;

  private destroy$ = new Subject<void>();
  store = inject(GlobalStore);
  countries = this.store.countries;

  constructor(@Inject(Injector) private injector: Injector) {}

  ngOnInit(): void {
    this.setComponentControl();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['isDisabled'] && this.control) {
      if (this.isDisabled) {
        this.control.disable();
      } else {
        this.control.enable();
      }
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onChange(value: string): void {
    // dummy
  }

  onTouched(): void {
    // dummy
  }

  writeValue(value: string): void {
    this.onChange(value);
  }

  registerOnChange(fn: (value: string) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  getAllControlErrors(control: FormControl): string[] {
    return Object.keys(control.errors ?? {});
  }

  getPlaceholder(): string {
    const placeholder = this.placeholder ?? this.label;
    if (placeholder) {
      return placeholder + (this.isRequired ? ' *' : '');
    }
    return '';
  }

  private setComponentControl(): void {
    const injectedControl = this.injector.get(NgControl);

    switch (injectedControl.constructor) {
      case NgModel: {
        const { control, update } = injectedControl as NgModel;
        this.control = control;
        if (!this.isRequired) {
          this.isRequired = this.control.hasValidator(Validators.required);
        }
        this.control.valueChanges
          .pipe(
            tap(value => update.emit(value)),
            takeUntil(this.destroy$),
          )
          .subscribe();
        break;
      }
      case FormControlName: {
        this.control = this.injector.get(FormGroupDirective).getControl(injectedControl as FormControlName);
        if (!this.isRequired) {
          this.isRequired = this.control.hasValidator(Validators.required);
        }
        break;
      }
      default: {
        this.control = (injectedControl as FormControlDirective).form;
        break;
      }
    }

    if (this.isDisabled) {
      this.control.disable();
    }
  }

  changeRadioEvent($event: Event): void {
    this.writeValue(this.value);
    this.changeRadio.emit($event);
  }
}

type TextBasedInputTypes = 'text' | 'number' | 'email' | 'password' | 'date' | 'textarea' | 'editor';
type DropdownBasedInputTypes = 'select' | 'country';
type RadioAndCheckboxInputTypes = 'radio' | 'checkbox' | 'toggle';

type InputTypes = TextBasedInputTypes | DropdownBasedInputTypes | RadioAndCheckboxInputTypes;
