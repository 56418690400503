<div class="d-flex">
  <label *ngIf="labelKey" class="me-2 pt-1 d-flex align-items-center">{{ labelKey | translate }}</label>
  <div ngbDropdown class="dropdown d-flex country-selector" data-cy="language-selector">
    <a ngbDropdownToggle class="d-flex nav-link icon leading-none" data-bs-toggle="dropdown" aria-expanded="true">
      <img [src]="currentLanguageFlagUrl" alt="img" class="align-self-center" />
    </a>
    <div ngbDropdownMenu class="dropdown-menu">
      <a
        *ngFor="let lang of languages"
        ngbDropdownItem
        [attr.data-cy]="'language-' + lang"
        (click)="changeLanguage(lang)"
        class="dropdown-item d-flex pb-2 px-4"
      >
        <img [src]="getLanguageUrl(lang)" alt="flag-img" class="avatar me-3 align-self-center" />
        <div class="countries">
          <strong>{{ getLanguageName(lang) }}</strong>
        </div>
      </a>
    </div>
  </div>
</div>
