/**
 * Privacy-Plan WebApp
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { UserActions } from './userActions';


export interface UserStatusDTO { 
    requiredActions?: Array<UserActions>;
    admin?: boolean;
    auditor?: boolean;
    user?: boolean;
}

