<div class="form-group" *ngIf="type !== 'radio'">
  <label *ngIf="label" [for]="label" class="form-label">{{ label }} {{ isRequired ? '*' : '' }}</label>
  <input
    *ngIf="control && ['text', 'password', 'email', 'number'].includes(type)"
    [class.ng-invalid]="control.invalid && control.touched"
    [formControl]="control"
    [id]="label ?? placeholder"
    [placeholder]="(label ?? placeholder) + (isRequired ? ' *' : '')"
    [type]="type"
    class="form-control"
    [attr.autocomplete]="autocomplete ? 'on' : 'off'"
  />
  <!--
   https://stackoverflow.com/questions/16736198/typing-in-textarea-super-slow-when-lots-of-text-inside
   Performance issue, VERY SLOW without theses things, especially spellcheck -->

  <textarea
    autocomplete="off"
    autocorrect="off"
    autocapitalize="off"
    spellcheck="false"
    *ngIf="type === 'textarea'"
    class="form-control"
    [rows]="rows"
    [formControl]="control"
    [placeholder]="placeholder"
  ></textarea>

  <quill-editor class="w-100" *ngIf="type === 'editor'" [formControl]="control"></quill-editor>
  <!-- ng-select without bindValue: Emits the whole object -->
  <ng-select
    *ngIf="type === 'select' && !bindValue"
    [bindLabel]="bindLabel"
    [formControl]="control"
    [items]="items || []"
    [labelForId]="label"
    [placeholder]="getPlaceholder()"
    class="form-control"
    appendTo="body"
  >
  </ng-select>

  <!-- ng-select with bindValue: Emits only the property defined in bindValue -->
  <ng-select
    *ngIf="type === 'select' && bindValue"
    [bindLabel]="bindLabel"
    [bindValue]="bindValue"
    [formControl]="control"
    [items]="items || []"
    [labelForId]="label"
    [placeholder]="getPlaceholder()"
    class="form-control"
    appendTo="body"
  >
  </ng-select>

  <ng-select
    *ngIf="type === 'country'"
    [formControl]="control"
    [bindLabel]="'name'"
    [labelForId]="label"
    [placeholder]="getPlaceholder()"
    class="form-control"
    appendTo="body"
  >
    <ng-template ng-label-tmp let-country="item">
      <span [ngClass]="'flag-icon flag-icon-' + country.code.toLowerCase()"></span>
      {{ country.name }}
    </ng-template>
    <ng-option *ngFor="let country of countries()" [value]="country">
      <span [ngClass]="'flag-icon flag-icon-' + country.code.toLowerCase()"></span>
      {{ country.name }}
    </ng-option>
  </ng-select>

  <app-toggle-button *ngIf="type === 'toggle'" ngDefaultControl [formControl]="control"></app-toggle-button>
</div>

<div class="form-check" *ngIf="type === 'radio'">
  <input
    [id]="label"
    (change)="changeRadioEvent($event)"
    class="form-check-input"
    [formControl]="control"
    [value]="value"
    [checked]="checked"
    [type]="type"
    [name]="name"
    id="flexRadioDefault1"
  />
  <label class="form-check-label" [for]="label">
    {{ label }}
  </label>
</div>

<div *ngIf="!hideErrors && control.invalid && (control.dirty || control.touched)">
  <div>
    <ng-container *ngIf="control.errors && control.touched">
      <app-notification
        *ngFor="let error of getAllControlErrors(control)"
        [icon]="'exclamation-circle-fill'"
        [text]="error"
        [type]="'danger'"
        class="my-2"
      ></app-notification>
    </ng-container>
  </div>
</div>
