/**
 * Privacy-Plan WebApp
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CountryDTO } from './countryDTO';


export interface InvoiceAddressDTO { 
    id?: string;
    firstName?: string;
    lastName?: string;
    department?: string;
    street?: string;
    zipCode?: string;
    city?: string;
    country?: CountryDTO;
}

