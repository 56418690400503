import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalStore, ROLE } from '../services/global.store';

@Injectable({
  providedIn: 'root',
})
export class AdminGuard {
  store = inject(GlobalStore);

  constructor(private router: Router) {}

  canActivate(): boolean {
    const userRoles = this.store.roles();
    if (userRoles?.includes(ROLE.Admin)) {
      return true;
    } else {
      this.router.navigate(['/home']);
      return false;
    }
  }
}
