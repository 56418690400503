import { Component, inject, Signal } from '@angular/core';
import { NavService } from 'src/app/shared/services/nav.service';
import { Auth } from 'aws-amplify';
import { CompanyDTO, UserDTO } from 'src/app/connectors';
import { UtilsService } from '../../services/utils.service';
import { GlobalStore } from '../../services/global.store';
import { DeepSignal } from '@ngrx/signals/src/deep-signal';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  isCollapsed = true;
  store = inject(GlobalStore);

  user: Signal<UserDTO> = this.store.user;
  username: Signal<string> = this.store.username;
  companyOfUser: DeepSignal<CompanyDTO> = this.store.companyOfUser;

  constructor(
    private utils: UtilsService,
    private navServices: NavService,
  ) {}

  async signOut() {
    await Auth.signOut();
  }

  copyTextToClipboard(text: string) {
    navigator.clipboard.writeText(text);
  }

  toggleSidebar() {
    if ((this.navServices.collapseSidebar = true)) {
      document.querySelector('body')?.classList.toggle('sidenav-toggled');
    }
  }
}
