<ng-toggle
  [value]="value"
  [color]="{
    unchecked: 'lightgray',
    checked: '#6699cc'
  }"
  [values]="{ checked: true, unchecked: false }"
  (valueChange)="change()"
  [labels]="labels"
  [width]="width"
  [height]="height"
  [disabled]="disabled"
></ng-toggle>
<span *ngIf="description" class="ms-2">{{ description }}</span>
