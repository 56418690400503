/**
 * Privacy-Plan WebApp
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AssessmentStatus } from './assessmentStatus';
import { CompanyDTO } from './companyDTO';
import { PartnershipStatus } from './partnershipStatus';


export interface PartnershipDetailsDTO { 
    id?: string;
    status?: PartnershipStatus;
    customer?: CompanyDTO;
    supplier?: CompanyDTO;
    supplierNumberOfSubcontractors?: number;
    supplierAssessmentId?: string;
    supplierAssessmentStatus?: AssessmentStatus;
    relevant?: boolean;
}
export namespace PartnershipDetailsDTO {
}


