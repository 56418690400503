<div class="mb-0">
  <p class="mb-1">
    <span
      >{{
        statementsAnswered +
          ('global.of' | translate) +
          totalQuestions +
          ('global.questions' | translate) +
          ((isAudit ? 'global.audited' : 'global.answered') | translate)
      }}<span class="ms-1">({{ getPercentage() | percent: '1.0-2' }})</span></span
    >
  </p>
  <div class="progress h-1">
    <div class="progress-bar bg-success" role="progressbar" [style.width]="getPercentage() | percent: '1.0-2'"></div>
  </div>
</div>
